import Config from 'app/config';
import { styled, useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { LocaleContext } from 'baseui/esm/locale/index';
import { FormControl } from 'baseui/form-control';
import { ArrowRight } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import { validate as validateEmail } from 'email-validator';
import React, { useContext, useEffect, useRef, useState } from 'react';

// const Field = Form.Item;
declare var grecaptcha: any;

export interface FormError {
  message: string;
}

const ResetPass = props => {
  const { onSubmit, errors } = props;
  const locale = (useContext(LocaleContext) as any).password;
  const [css, theme] = useStyletron();
  const recaptchaRef = useRef<HTMLDivElement>();
  const captchaWidgetRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);

  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isVisited, setIsVisited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const disabledButton = !isValid || !captchaToken;

  const captchaErrorCallback = () => {
    setCaptchaToken(null)
    alert(locale.errorValidation)
  }

  useEffect(() => {
    if (recaptchaRef.current) {
      grecaptcha.enterprise.ready(function () {
        if (captchaWidgetRef.current === null) {
          captchaWidgetRef.current = grecaptcha.enterprise.render(recaptchaRef.current, {
            sitekey: Config.RECAPTCHA_SITEKEY,
            action: 'LOGIN',
            callback: setCaptchaToken,
            'expired-callback': captchaErrorCallback,
            'error-callback': captchaErrorCallback
          })
        }
      });
    }
  }, [recaptchaRef.current]);

  useEffect(() => {
    if (errors?.length > 0) {
      setIsLoading(false);
      if (captchaWidgetRef.current !== null) {
        grecaptcha.enterprise.reset(captchaWidgetRef.current);
      }
    }
  }, [errors]);


  useEffect(() => {
    if (errors?.length > 0) {
      setIsLoading(false);
    }
  }, [errors]);

  const onChangeEmail = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsValid(validateEmail(value));
    setEmail(value);
  };

  const checkBeforeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    onSubmit({ email, captchaToken })
  }

  return (
    <Form onSubmit={checkBeforeSubmit}>
      <p className={css({
        textAlign: 'center',
        marginBottom: '16px',
        lineHeight: '28px',
        fontSize: '20px'
      })}>{locale.reenter}</p>
      <FormControl
        label={() => locale.enterEmail}
        error={!isValid && isVisited ? locale.formatEmail : null}
      >
        <Input
          type="email"
          placeholder={locale.enterEmail}
          autoFocus
          value={email}
          onChange={onChangeEmail}
          onBlur={() => setIsVisited(true)}
          error={!isValid && isVisited}
          overrides={{
            Root: {
              style: {
                backgroundColor: 'transparent'
              }
            },
            InputContainer: {
              style: {
                backgroundColor: 'transparent'
              }
            }
          }} />
      </FormControl>
      <div id="recaptcha" ref={recaptchaRef} className={css({
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
      })}></div>
      <Button
        type="submit"
        size={SIZE.large}
        endEnhancer={() => <ArrowRight size={30} />}
        disabled={disabledButton}
        isLoading={isLoading}
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              position: 'relative',
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: 500
            }
          },
          EndEnhancer: {
            style: {
              position: 'absolute',
              right: '20px'
            }
          }
        }}
      >
        {locale.request}
      </Button>
    </Form>
  );
};

const Form = styled('form', ({ $theme }) => {
  return {
    width: '360px'
  }
});

export default ResetPass;
